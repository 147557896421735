<template>
  <div>
    <ViewOrderDetails
      :orderId="orderId"
      :supplierId="supplierId"
    />
  </div>
</template>

<script>
import ViewOrderDetails from "../../../components/WebStore/suppliers/OrderDetails";
import { mapActions } from 'vuex';

export default {
  name: 'View-Order-Details',
  components:{
    ViewOrderDetails
  },
  data: () => ({
    orderId: '',
    supplierId:''
  }),
  methods:{},
  async created() {
    this.orderId = this.$route.params.orderId;
    this.supplierId = this.$route.params.supplierId;
  }
};
</script>
