<template>
  <vs-card>
    <vs-row vs-align="flex-start" vs-type="flex" vs-justify="left" vs-w="12">
      <vs-col vs-type="flex" vs-justify="left" vs-align="left" vs-w="8">
        <div class="w-full m-5">
          <h1>Order ID #{{ orderData.orderNumber }}</h1>
        </div>
      </vs-col>
      <vs-col vs-type="flex" vs-justify="left" vs-align="left" vs-w="4">
        <div class="w-full m-5" align="right">
          <h1>{{ moment(orderData.createdAt).format("MMM DD YYYY") }}</h1>
        </div>
      </vs-col>
      <div style="font-size: 15px">
      <vs-col vs-type="flex" vs-justify="left" vs-align="left" vs-w="6">
        <div class="w-full m-5">
        <vs-row vs-align="flex-start" vs-type="flex" vs-justify="left" vs-w="12">
          <vs-col vs-type="flex" vs-justify="left" vs-align="left" vs-w="12">
            <div class="w-full mb-5">
              <p><strong>Order No: </strong>{{ orderData.orderNumber}}</p>
            </div>
          </vs-col>
          <vs-col vs-type="flex" vs-justify="left" vs-align="left" vs-w="12">
            <div class="w-full mb-5">
              <p><strong>Order To: </strong>{{ orderData.shippingDetails.companyName | capitalize }}</p>
            </div>
          </vs-col>
          <vs-col vs-type="flex" vs-justify="left" vs-align="left" vs-w="12">
            <div class="w-full mb-5">
              <p><strong>Order From: </strong>{{ orderData.shippingDetails.companyName | capitalize }}</p>
            </div>
          </vs-col>
          <vs-col vs-type="flex" vs-justify="left" vs-align="left" vs-w="12">
            <div class="w-full mb-5">
              <p><strong>Order Date: </strong>{{ orderData.createdAt | date_formatter }}</p>
            </div>
          </vs-col>
        </vs-row>
        </div>
      </vs-col>
      <vs-col vs-type="flex" vs-justify="left" vs-align="left" vs-w="6">
        <div class="w-full mt-5 mx-5 mb-3">
          <vs-row vs-align="flex-start" vs-type="flex" vs-justify="left" vs-w="12">
            <vs-col vs-type="flex" vs-justify="left" vs-align="left" vs-w="12">
              <div class="w-full">
                <p><strong>Shipping Address</strong></p>
              </div>
            </vs-col>
            <vs-col vs-type="flex" vs-justify="left" vs-align="left" vs-w="12">
              <div class="w-full">
                <p>
                  {{ orderData.shippingDetails.clientName | capitalize }}
                </p>
              </div>
            </vs-col>
            <vs-col vs-type="flex" vs-justify="left" vs-align="left" vs-w="12">
              <div class="w-full">
                <p>
                  {{ orderData.shippingDetails.address.displayAddress | capitalize }}
                </p>
              </div>
            </vs-col>
            <vs-col vs-type="flex" vs-justify="left" vs-align="left" vs-w="12">
              <div class="w-full">
                <p>
                  {{ orderData.shippingDetails.address.country | capitalize }}
                </p>
              </div>
            </vs-col>
          </vs-row>
        </div>
      </vs-col>
       <vs-col vs-type="flex" vs-justify="left" vs-align="left" vs-w="6">
        <div class="w-full m-5">
          <vs-row vs-align="flex-start" vs-type="flex" vs-justify="left" vs-w="12">
            <vs-col vs-type="flex" vs-justify="left" vs-align="left" vs-w="12">
              <div class="w-full">
                <p><strong>Clinic Hours</strong></p>
              </div>
            </vs-col>
            <vs-col vs-type="flex" vs-justify="left" vs-align="left" vs-w="12">
              <div class="w-full sm:inline-flex">
                <div>
                  Monday: {{ orderData.operations.monday}} <br/>
                  Tuesday: {{ orderData.operations.tuesday}} <br/>
                  Wednesday: {{ orderData.operations.wednesday}} <br/>
                  Thursday: {{ orderData.operations.thursday}} <br/>
                  Friday: {{ orderData.operations.friday}} <br/>
                </div>
                <div class="sm:ml-10">
                  Saturday: {{ orderData.operations.saturday}} <br/>
                  Sunday: {{ orderData.operations.sunday}} <br/>
                </div>
              </div>
            </vs-col>
          </vs-row>
        </div>
      </vs-col>
      </div>
      <vs-col vs-type="flex" vs-justify="left" vs-align="left" vs-w="12">
        <div class="w-full m-5">
          <h1>Products</h1>
        </div>
      </vs-col>
      <vs-col vs-type="flex" vs-justify="left" vs-align="left" vs-w="12">
        <div class="w-full m-5">
          <div id="data-list-list-view" class="data-list-container">
            <vs-table
              :data="productList"
            >
              <template slot="thead">
                <vs-th>Product Details</vs-th>
                <vs-th>Amount Paid</vs-th>
                <vs-th>Quantity</vs-th>
              </template>

              <template slot-scope="{ data }">
                <vs-tr :data="tr" :key="indextr" v-for="(tr, indextr) in data">
                  <vs-td>
                    <div class="inline-flex">
                      <div style="width: 100px; height: auto;">
                        <img
                          class="img-fluid"
                          :src="data[indextr].image"
                          alt=""
                        />
                      </div>
                      <div class="mt-10 ml-10">
                          {{
                        data[indextr].name | capitalize
                      }}
                      </div>
                    </div>
                  </vs-td>
                  <vs-td>
                    ${{
                      ((data[indextr].costPrice)/100).toFixed(2)
                    }}
                  </vs-td>
                  <vs-td>
                    {{data[indextr].quantity}}
                  </vs-td>
                </vs-tr>
              </template>
            </vs-table>
          </div>
        </div>
      </vs-col>
    </vs-row>
  </vs-card>
</template>

<script>

import moment from "moment";
import { mapActions } from 'vuex';

export default {
  name: 'OrderDetails',
  components: {},
  props:{
    orderId: {
      type: String
    },
    supplierId: {
      type: String
    }
   },
  data() {
    return {
      id: this.orderId,
      supplier: this.supplierId,
      orderData: {},
      productList: []
    };
  },
  computed: {
    validateForm() {
      return !this.errors.any()
    },
  },
  mounted() {
  },
  methods: {
    ...mapActions("supplier", [
      "getOrderDetails"
    ]),
    moment(date) {
      return moment(date);
    },
    async fetchOrderDetails(supplierId, id){
      this.$vs.loading();
      let self=this;
      const info = {
        supplierId: supplierId,
        orderId: id
      };
      await this.getOrderDetails(info).then(res => {
        self.orderData = res.data.data;
        self.productList = self.orderData.productList;
        this.$vs.loading.close();
      });
    },
  },
  async created() {
    this.id = this.orderId;
    this.supplier = this.supplierId;
    await this.fetchOrderDetails(this.supplier, this.id);
  }

};
</script>

<style scoped>
</style>
